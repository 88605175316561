import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import PDFModal from "../components/PDFModal";
import PortfolioTile from "../components/PortfolioTile";
import ResumeTile from "../components/ResumeTile";

import "../styles/index.scss";

const IndexPage = ({ data }) => {
  const [
    portfolioModalTitle,
    setPortfolioModalTitle,
  ] = React.useState("");

  const [
    portfolioModalFilePath,
    setPortfolioModalFilePath,
  ] = React.useState("");

  const [
    portfolioModalIsVisible,
    setPortfolioModalIsVisible,
  ] = React.useState(false);

  const [
    resumeModalIsVisible,
    setResumeModalIsVisible,
  ] = React.useState(false);

  return (
    <>
      <Navbar bg="white" variant="light" fixed="top">
        <Container>
          <Navbar.Brand onClick={() => scrollTo("#home")}>
            STEPH SILVER
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link onClick={() => scrollTo("#home")}>HOME</Nav.Link>
              <Nav.Link onClick={() => scrollTo("#about")}>ABOUT</Nav.Link>
              <Nav.Link onClick={() => scrollTo("#portfolio")}>
                PORTFOLIO
              </Nav.Link>
              <Nav.Link onClick={() => scrollTo("#resume")}>RESUME</Nav.Link>
              <Nav.Link onClick={() => scrollTo("#contact")}>CONTACT</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div id="home" />

      {/* jumbotron */}
      <Container className="my-5 py-5 px-0">
        <div
          style={{
            height: "600px",
            backgroundImage: `url(${data.backgroundImage.childImageSharp.fluid.src})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          className="d-flex flex-column justify-content-center align-items-center text-white text-center"
        >
          <h1
            style={{
              fontSize: "50px",
              textTransform: "uppercase",
              fontWeight: "300",
              letterSpacing: "25px",
            }}
            className="mb-3"
          >
            Steph Silver
          </h1>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "300",
              textTransform: "uppercase",
              letterSpacing: "11px",
            }}
          >
            LEADERSHIP & ORG DESIGN
          </h2>
        </div>
      </Container>

      <div id="about" className="section-link">
        about
      </div>

      <Container className="my-5 py-5">
        <Row>
          <Col
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 5, offset: 1 }}
            style={{}}
            className="d-flex justify-content-center mb-3"
          >
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
              }}
            >
              <Img fixed={data.headshotImage.childImageSharp.fixed} />
            </div>
          </Col>
          <Col
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 5 }}
            style={{
              fontFamily: "raleway",
              fontSize: "20px",
              lineHeight: "1.6em",
            }}
            className="d-flex justify-content-center text-center align-items-center"
          >
            I'm Steph. When it comes to work, my favorite intro is to ask "where
            are the pain points?" From there, I work with people to drive
            results for clients and internal teams. From designing training
            programs for teams to developing services for customers, I love to
            create value and delightful experiences where frustration lived
            before.
          </Col>
        </Row>
      </Container>

      <div id="portfolio" className="section-link">
        portfolio
      </div>

      <Container className="my-5 py-5">
        <Row>
          <Col
            md={{ span: 6 }}
            lg={{ span: 5, offset: 1 }}
            className="d-flex justify-content-center mb-4"
          >
            <PortfolioTile
              title="New Hire Training"
              onClick={() => {
                setPortfolioModalTitle("New Hire Training");
                setPortfolioModalFilePath("/new_hire_training.pdf");
                setPortfolioModalIsVisible(true);
              }}
            />
          </Col>

          <Col
            md={{ span: 6 }}
            lg={{ span: 5 }}
            className="d-flex justify-content-center mb-4"
          >
            <PortfolioTile
              title="Sales Battlecard"
              onClick={() => {
                setPortfolioModalTitle("Sales Battlecard");
                setPortfolioModalFilePath("/Sales_Battlecard.pdf");
                setPortfolioModalIsVisible(true);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            md={{ span: 6 }}
            lg={{ span: 5, offset: 1 }}
            className="d-flex justify-content-center mb-4"
          >
            <PortfolioTile
              title="Tech Stack Audit"
              onClick={() => {
                setPortfolioModalTitle("Tech Stack Audit");
                setPortfolioModalFilePath("/Tech_Stack_Audit.pdf");
                setPortfolioModalIsVisible(true);
              }}
            />
          </Col>

          <Col
            md={{ span: 6 }}
            lg={{ span: 5 }}
            className="d-flex justify-content-center mb-4"
          >
            <PortfolioTile
              title="Measured Results and Skills"
              onClick={() => {
                setPortfolioModalTitle("Measured Results and Skills");
                setPortfolioModalFilePath("/Measured_Results_and_Skills.pdf");
                setPortfolioModalIsVisible(true);
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col
            md={{ span: 6 }}
            lg={{ span: 5, offset: 1 }}
            className="d-flex justify-content-center mb-4"
          >
            <PortfolioTile
              title=" Sales / Service Training"
              onClick={() => {
                setPortfolioModalTitle(" Sales / Service Training");
                setPortfolioModalFilePath("/Sales_Service_Training.pdf");
                setPortfolioModalIsVisible(true);
              }}
            />
          </Col>

          <Col
            md={{ span: 6 }}
            lg={{ span: 5 }}
            className="d-flex justify-content-center mb-4"
          >
            <PortfolioTile
              title="Ramp Event MC Script"
              onClick={() => {
                setPortfolioModalTitle("Ramp Event MC Script");
                setPortfolioModalFilePath("/Ramp_Event_MC_Script.pdf");
                setPortfolioModalIsVisible(true);
              }}
            />
          </Col>
        </Row>

        <PDFModal
          header={portfolioModalTitle}
          filePath={portfolioModalFilePath}
          modalIsVisible={portfolioModalIsVisible}
          onClose={setPortfolioModalIsVisible}
        />
      </Container>

      <div id="resume" className="section-link">
        resume
      </div>

      <Container className="my-5 py-5">
        <Row>
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <ResumeTile onClick={() => setResumeModalIsVisible(true)} />
          </Col>
        </Row>

        <PDFModal
          header="Resume"
          filePath="/Stephanie_Silver_resume.pdf"
          modalIsVisible={resumeModalIsVisible}
          onClose={setResumeModalIsVisible}
        />
      </Container>

      <div id="contact" className="section-link">
        contact
      </div>

      <Container className="my-3 mb-5 py-5 text-center font-weight-light">
        <h3 style={{ fontSize: "20px" }} className="font-weight-light">
          Let’s get in touch!
        </h3>
        <p className="mb-0">Stephanie Silver</p>
        <p className="mb-0">503.680.0185</p>
        <p className="mb-0">
          <a href="mailto:silver.stephaniem@gmail.com">
            silver.stephaniem@gmail.com
          </a>
        </p>
        <p className="mb-0">
          <a
            href="https://www.linkedin.com/in/stephsilver/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.linkedin.com/in/stephsilver/
          </a>
        </p>
      </Container>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    backgroundImage: file(relativePath: { eq: "green-sea.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    headshotImage: file(relativePath: { eq: "steph.jpg" }) {
      childImageSharp {
        fixed(height: 370, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
