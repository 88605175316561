import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { IoMdDownload } from 'react-icons/io';

const ResumeTile = ({ onClick, data }) => {
  return (
    <>
      <div
        onClick={onClick}
        className="resume-tile"
        style={{
          backgroundImage: `url(${data.resumeScreenshot.childImageSharp.fixed.src})`,
        }}
      >
        <div className="resume-tile__content">Resume</div>
      </div>

      <a
        href="/Stephanie_Silver_resume.pdf"
        className="mt-3 pl-2 d-block"
        download
      >
        <span className="mr-1">
          download resume
        </span>
        <IoMdDownload />
      </a>
    </>
  );
};

export default function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          resumeScreenshot: file(
            relativePath: { eq: "resume-screenshot.png" }
          ) {
            childImageSharp {
              fixed(height: 370, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => <ResumeTile data={data} {...props} />}
    />
  );
}
