import React from "react";

const PortfolioTile = ({ title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="portfolio-tile"
    >
      <div className="portfolio-tile__content">
        {title}
      </div>
    </div>
  );
};

export default PortfolioTile;
