import React from "react";

import Modal from "react-bootstrap/Modal";

const PDFModal = ({header, filePath, modalIsVisible, onClose}) => (
  <Modal
    dialogClassName="custom-modal-width"
    centered
    show={modalIsVisible}
    onHide={onClose}
  >
    <Modal.Header closeButton>
      <Modal.Title
        style={{
          fontWeight: 300,
          fontFamily: "serif",
          fontStyle: "italic",
          fontSize: "20px"
        }}
      >
        {header}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <object
        type="application/pdf"
        data={filePath}
        width="100%"
        height="500"
        style={{ height: "75vh" }}
      >
        No Support
      </object>
    </Modal.Body>
  </Modal>
);

export default PDFModal;
